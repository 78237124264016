import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { AdvisorCustomerProjectsList } from '@Components/network';
import { ButtonIcon, Text } from '@Components/ui';
import { useAppRoutes } from '@Hooks/Common';
import { useCustomerGetCurrent } from '@Hooks/Customer';

import styles from './AdvisorCustomerDetailScreen.module.scss';

export const AdvisorCustomerDetailScreen = () => {
  const { data } = useCustomerGetCurrent();
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.PROJECTS' });
  const appRoutes = useAppRoutes();

  return (
    <>
      <section className={styles.AdvisorCustomerDetailScreen}>
        <header className={styles.Header}>
          <Text.H1 className={styles.Title} color="primary">
            {data?.name}
          </Text.H1>

          <ButtonIcon
            className={styles.Button}
            icon={faPlus}
            size={22}
            backgroundColor="secondary"
            color="white"
            accessibilityLabel={t('ADD_PROJECT')}
            to={appRoutes.getAdvisorProjectCreate(data?.publicId).to}
          />
        </header>

        <AdvisorCustomerProjectsList />
      </section>

      <Outlet />
    </>
  );
};
