import { ContactCard } from '@Components/ui';
import { getReadableAddress } from '@Utils/StringUtils';

import type { ProjectContactProps } from './ProjectContact.props';

export const ProjectContact = ({ project }: ProjectContactProps) => {
  return (
    <ContactCard
      title={project.name}
      address={getReadableAddress(project.customer)}
      name={project.customer.name}
      phone={project.customer.phone}
      email={project.customer.email}
    />
  );
};
