import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CustomerProjectCard } from '@Components/container';
import { PreviewWrapper } from '@Components/layout';
import { QueryIndicator } from '@Components/ui';
import { useAppRoutes, useInfiniteData } from '@Hooks/Common';
import { useProjectGetForCurrentCustomer } from '@Hooks/Project';

import type { CustomerProjectsPreviewProps } from './CustomerProjectsPreview.props';

export const CustomerProjectsPreview = (props: CustomerProjectsPreviewProps) => {
  const { t } = useTranslation();
  const { customerId } = useParams();
  const appRoutes = useAppRoutes();
  const { data: customerProjectsPages, ...query } = useProjectGetForCurrentCustomer({ perPage: 3 });
  const customerProjects = useInfiniteData(customerProjectsPages);

  return (
    <QueryIndicator {...query} modelName={t('DOMAIN.PROJECT.PLURAL')} {...props}>
      {customerProjects && (
        <PreviewWrapper
          title={t('DOMAIN.CUSTOMER.HOME_PAGE.PROJECTS.TITLE')}
          buttonLabel={t('DOMAIN.CUSTOMER.HOME_PAGE.PROJECTS.BUTTON_LABEL')}
          buttonTo={appRoutes.getCustomerProjects(customerId).to}
        >
          {customerProjects.map((project) => {
            return <CustomerProjectCard project={project} key={project.id} />;
          })}
        </PreviewWrapper>
      )}
    </QueryIndicator>
  );
};
