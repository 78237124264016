import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import type { UpdateAccountFormValues } from '@Components/form';
import { UpdateAccountForm } from '@Components/form';
import { Modal } from '@Components/layout';
import { useAdvisorAccountUpdate } from '@Hooks/Advisor';
import { useAuthGetMe } from '@Hooks/Auth';
import { useAppRoutes } from '@Hooks/Common';
import type { IUser } from '@Services/AuthService';
import { storageService } from '@Services/Storage';

export const AdvisorAccountUpdateScreen = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.ACCOUNT.FORM.UPDATE'
  });
  const { data: advisor } = useAuthGetMe();
  const appRoutes = useAppRoutes();
  const navigate = useNavigate();
  const { mutateAsync: updateAccount } = useAdvisorAccountUpdate();

  if (!advisor) return null;

  const { firstName, lastName, phoneNumber, language, id } = advisor as IUser;

  const handleClose = () => {
    navigate(appRoutes.getAdvisorAccount().to);
  };

  const handleSubmit = async (data: UpdateAccountFormValues) => {
    handleClose();

    try {
      if (data.image) {
        const response = await storageService.upload(data.image as any);

        if (response && response[0].id) data.image = { id: response[0].id };
      }

      await updateAccount({
        ...data,
        language: language,
        id: id
      });

      toast.success(t('SUCCESS_MESSAGE'));
    } catch (error) {
      toast.error(t('ERROR_MESSAGE'));
    }
  };

  return (
    <Modal title={t('TITLE')} open onOpenChange={handleClose}>
      <UpdateAccountForm
        initialValues={{
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber
        }}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};
