import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { faFileInvoice } from '@fortawesome/pro-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ContactCard,
  ProjectBlog,
  ProjectContact,
  ProjectHero,
  ProjectQuoteCard,
  ProjectUspsWhitepaperCard,
  ProjectVideo
} from '@Components/container';
import { Notes, ProjectRooms } from '@Components/network';
import { Button, SpacingGroup } from '@Components/ui';
import { useProjectGetByCustomer } from '@Hooks/Project/useProjectGetByCustomer';

import styles from './CustomerProjectDetailScreen.module.scss';

export const CustomerProjectDetailScreen = () => {
  const { t } = useTranslation();
  const { customerId, projectId } = useParams();

  if (!customerId || !projectId) {
    throw new Error('Missing customer or project id');
  }

  const { data: project } = useProjectGetByCustomer({ customerId, projectId });

  if (!project) {
    return null;
  }

  return (
    <div className={styles.CustomerProjectDetailScreen}>
      <ProjectHero project={project} />

      <Row>
        <Col lg={8}>
          <SpacingGroup isVertical spacing={6}>
            <Notes project={project} />

            <ProjectContact project={project} />

            <ProjectRooms project={project} />
          </SpacingGroup>
        </Col>

        <Col lg={4} className={styles.MobileGap}>
          <SpacingGroup isVertical spacing={3}>
            {project.quotation && (
              <Button
                href={project.quotation}
                iconRight={faArrowRight}
                iconLeft={faFileInvoice}
                className={styles.InvoiceButton}
              >
                {t('DOMAIN.PROJECT.FORM.QUOTATION.DOWNLOAD')}
              </Button>
            )}

            <ContactCard contact={project.advisor} />

            <ProjectUspsWhitepaperCard project={project} />

            {<ProjectQuoteCard project={project} />}

            {project.blogs && <ProjectBlog blogs={project.blogs} />}

            {project.videoUrl && <ProjectVideo videoId={project.videoUrl} />}
          </SpacingGroup>
        </Col>
      </Row>
    </div>
  );
};
