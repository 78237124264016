import cn from 'classnames';

import { GridList } from '@Components/layout';
import { Button, Text } from '@Components/ui';

import styles from './PreviewWrapper.module.scss';
import type { PreviewWrapperProps } from './PreviewWrapper.props';

export const PreviewWrapper = ({
  buttonLabel,
  buttonTo,
  title,
  className,
  children,
  ...props
}: PreviewWrapperProps) => {
  const classNames = cn(styles.PreviewWrapper, className);

  return (
    <div className={classNames} {...props}>
      <Text.H2 className={styles.Title}>{title}</Text.H2>

      <GridList className={styles.Children}>{children}</GridList>

      <Button className={styles.Button} to={buttonTo} isOutline>
        {buttonLabel}
      </Button>
    </div>
  );
};
