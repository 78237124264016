import type { To } from 'react-router-dom';

import type { ICustomer } from '@Services/Customer';
import type { IProject } from '@Services/Project';
import type { IRoom } from '@Services/Room';
import type { ISolution } from '@Services/Solution';
import type { NavSlugs, TranslatedSlugs } from '@Utils/RouteUtils';
import { getLink, getLinkLabel } from '@Utils/RouteUtils';
import { i18n } from '@Utils/TranslationUtils';

const getSlugs = (locale: string) =>
  Object.fromEntries(
    Object.keys(i18n.t('COMMON.NAVIGATION', { returnObjects: true, lng: locale })).map((key) => [
      key,
      key
    ])
  ) as Record<TranslatedSlugs, TranslatedSlugs>;

export interface RouteType {
  path: string;
  label: string;
  to: To;
}

export type RouteMethod = (...args: NavSlugs[]) => RouteType;

type AppRoutesType = {
  [K in keyof AppRoutes]: K extends `get${Capitalize<string>}`
    ? K extends Lowercase<K>
      ? never
      : RouteMethod
    : AppRoutes[K];
} & {
  slugs: Record<TranslatedSlugs, TranslatedSlugs>;
};

export class AppRoutes implements AppRoutesType {
  slugs: Record<TranslatedSlugs, TranslatedSlugs>;

  constructor(locale: string) {
    this.slugs = getSlugs(locale);
  }

  getAdvisorHome = () => ({
    path: getLink(this.slugs.ADVISOR_HOME),
    label: getLinkLabel(this.slugs.ADVISOR_HOME),
    to: getLink(this.slugs.ADVISOR_HOME)
  });

  getCustomerHome = (customerId?: ICustomer['id']) => ({
    path: getLink(this.slugs.CUSTOMER_HOME),
    label: getLinkLabel(this.slugs.CUSTOMER_HOME),
    to: getLink(customerId)
  });

  getAdvisorCustomers = () => {
    const { path, to } = this.getAdvisorHome();
    return {
      path: getLink(path, this.slugs.ADVISOR_CUSTOMERS),
      label: getLinkLabel(this.slugs.ADVISOR_CUSTOMERS),
      to: getLink(to, this.slugs.ADVISOR_CUSTOMERS)
    };
  };

  getAdvisorCustomerDetail = (customerId?: ICustomer['id']) => {
    const { path, to } = this.getAdvisorCustomers();
    return {
      path: getLink(path, this.slugs.ADVISOR_CUSTOMER_DETAIL),
      label: getLinkLabel(this.slugs.ADVISOR_CUSTOMER_DETAIL),
      to: getLink(to, customerId)
    };
  };

  getAdvisorCustomersCreate = () => {
    const { path, to } = this.getAdvisorCustomers();
    return {
      path: getLink(path, this.slugs.ADVISOR_CUSTOMERS_CREATE),
      label: getLinkLabel(this.slugs.ADVISOR_CUSTOMERS_CREATE),
      to: getLink(to, this.slugs.ADVISOR_CUSTOMERS_CREATE)
    };
  };

  getAdvisorProjects = () => {
    const { path, to } = this.getAdvisorHome();
    return {
      path: getLink(path, this.slugs.PROJECTS),
      label: getLinkLabel(this.slugs.PROJECTS),
      to: getLink(to, this.slugs.PROJECTS)
    };
  };

  getCustomerProjects = (customerId?: ICustomer['id']) => {
    const { path, to } = this.getCustomerHome(customerId);
    return {
      path: getLink(path, this.slugs.PROJECTS),
      label: getLinkLabel(this.slugs.PROJECTS),
      to: getLink(to, this.slugs.PROJECTS)
    };
  };

  getCustomerProjectDetail = (customerId?: ICustomer['id'], projectId?: IProject['id']) => {
    const { path, to } = this.getCustomerProjects(customerId);
    return {
      path: getLink(path, this.slugs.PROJECTS_DETAIL),
      label: getLinkLabel(this.slugs.PROJECTS_DETAIL),
      to: getLink(to, projectId)
    };
  };

  getAdvisorProjectDetail = (projectId?: IProject['id']) => {
    const { path, to } = this.getAdvisorProjects();
    return {
      path: getLink(path, this.slugs.PROJECTS_DETAIL),
      label: getLinkLabel(this.slugs.PROJECTS_DETAIL),
      to: getLink(to, projectId)
    };
  };

  getAdvisorProjectCreate = (customerId?: ICustomer['id']) => {
    const { path, to } = this.getAdvisorCustomerDetail(customerId);
    return {
      path: getLink(path, this.slugs.PROJECTS_CREATE),
      label: getLinkLabel(this.slugs.PROJECTS_CREATE),
      to: getLink(to, this.slugs.PROJECTS_CREATE)
    };
  };

  getAdvisorProjectRoomCreate = (projectId?: IProject['id']) => {
    const { path, to } = this.getAdvisorProjectDetail(projectId);
    return {
      path: getLink(path, this.slugs.ROOM_CREATE),
      label: getLinkLabel(this.slugs.ROOM_CREATE),
      to: getLink(to, this.slugs.ROOM_CREATE)
    };
  };

  getAdvisorProjectRoomAddMeasurements = (projectId?: IProject['id'], roomId?: IRoom['id']) => {
    const { path, to } = this.getAdvisorProjectDetail(projectId);
    return {
      path: getLink(path, this.slugs.ROOM_ADD_MEASUREMENT),
      label: getLinkLabel(this.slugs.ROOM_ADD_MEASUREMENT),
      to: getLink(to, roomId, 'metingen')
    };
  };

  getAdvisorProjectRoomAddProduct = (projectId?: IProject['id'], roomId?: IRoom['id']) => {
    const { path, to } = this.getAdvisorProjectDetail(projectId);

    return {
      path: getLink(path, this.slugs.ROOM_ADD_PRODUCT),
      label: getLinkLabel(this.slugs.ROOM_ADD_PRODUCT),
      to: getLink(to, roomId, 'producten')
    };
  };

  getAdvisorProjectRoomEditProduct = (
    projectId?: IProject['id'],
    roomId?: IRoom['id'],
    solutionId?: ISolution['id']
  ) => {
    const { path, to } = this.getAdvisorProjectDetail(projectId);

    return {
      path: getLink(path, this.slugs.ROOM_EDIT_PRODUCT),
      label: getLinkLabel(this.slugs.ROOM_EDIT_PRODUCT),
      to: getLink(to, roomId, solutionId, 'producten')
    };
  };

  getAdvisorProjectQuotation = (projectId?: IProject['id']) => {
    const { path, to } = this.getAdvisorProjectDetail(projectId);
    return {
      path: getLink(path, this.slugs.PROJECTS_DETAIL_QUOTATION),
      label: getLinkLabel(this.slugs.PROJECTS_DETAIL_QUOTATION),
      to: getLink(to, this.slugs.PROJECTS_DETAIL_QUOTATION)
    };
  };

  getCustomerProducts = (customerId?: ICustomer['id']) => {
    const { path, to } = this.getCustomerHome(customerId);
    return {
      path: getLink(path, this.slugs.PRODUCTS),
      label: getLinkLabel(this.slugs.PRODUCTS),
      to: getLink(to, this.slugs.PRODUCTS)
    };
  };

  getAdvisorProducts = () => {
    const { path, to } = this.getAdvisorHome();
    return {
      path: getLink(path, this.slugs.PRODUCTS),
      label: getLinkLabel(this.slugs.PRODUCTS),
      to: getLink(to, this.slugs.PRODUCTS)
    };
  };

  getCustomerProductDetail = (customerId?: ICustomer['id'], productId?: string) => {
    const { path, to } = this.getCustomerProducts(customerId);
    return {
      path: getLink(path, this.slugs.PRODUCTS_DETAIL),
      label: getLinkLabel(this.slugs.PRODUCTS_DETAIL),
      to: getLink(to, productId)
    };
  };

  getAdvisorProductDetail = (productId?: string) => {
    const { path, to } = this.getAdvisorProducts();
    return {
      path: getLink(path, this.slugs.PRODUCTS_DETAIL),
      label: getLinkLabel(this.slugs.PRODUCTS_DETAIL),
      to: getLink(to, productId)
    };
  };

  getCustomerAccount = (customerId?: ICustomer['id']) => {
    const { path, to } = this.getCustomerHome(customerId);
    return {
      path: getLink(path, this.slugs.ACCOUNT),
      label: getLinkLabel(this.slugs.ACCOUNT),
      to: getLink(to, this.slugs.ACCOUNT)
    };
  };

  getAdvisorAccount = () => {
    const { path, to } = this.getAdvisorHome();
    return {
      path: getLink(path, this.slugs.ACCOUNT),
      label: getLinkLabel(this.slugs.ACCOUNT),
      to: getLink(to, this.slugs.ACCOUNT)
    };
  };

  getAdvisorAccountUpdate = () => {
    const { path, to } = this.getAdvisorAccount();
    return {
      path: getLink(path, this.slugs.EDIT_ACCOUNT),
      label: getLinkLabel(this.slugs.EDIT_ACCOUNT),
      to: getLink(to, this.slugs.EDIT_ACCOUNT)
    };
  };

  getLogin = () => ({
    path: getLink(this.slugs.LOGIN),
    label: getLinkLabel(this.slugs.LOGIN),
    to: getLink(this.slugs.LOGIN)
  });

  getForgotPassword = () => ({
    path: getLink(this.slugs.FORGOT_PASSWORD),
    label: getLinkLabel(this.slugs.FORGOT_PASSWORD),
    to: getLink(this.slugs.FORGOT_PASSWORD)
  });

  getUpdatePassword = () => ({
    path: getLink(this.slugs.EDIT_PASSWORD),
    label: getLinkLabel(this.slugs.EDIT_PASSWORD),
    to: getLink(this.slugs.EDIT_PASSWORD)
  });

  getRegister = () => ({
    path: getLink(this.slugs.REGISTER),
    label: getLinkLabel(this.slugs.REGISTER),
    to: getLink(this.slugs.REGISTER)
  });

  getRegisterComplete = () => ({
    path: getLink(this.slugs.REGISTER_COMPLETE),
    label: getLinkLabel(this.slugs.REGISTER_COMPLETE),
    to: getLink(this.slugs.REGISTER_COMPLETE)
  });

  getRegisterCompleteAdvisor = () => ({
    path: getLink(this.slugs.REGISTER_COMPLETE_ADVISOR),
    label: getLinkLabel(this.slugs.REGISTER_COMPLETE_ADVISOR),
    to: getLink(this.slugs.REGISTER_COMPLETE_ADVISOR)
  });

  getUpdatePasswordComplete = () => ({
    path: getLink(this.slugs.EDIT_PASSWORD_COMPLETE),
    label: getLinkLabel(this.slugs.EDIT_PASSWORD_COMPLETE),
    to: getLink(this.slugs.ADVISOR_HOME, this.slugs.EDIT_PASSWORD_COMPLETE)
  });
}
