import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';

import { ButtonIcon, Text } from '@Components/ui';
import { useSolutionChangeQuantity } from '@Hooks/Solution/useSolutionChangeQuantity';

import styles from './TitleQuantity.module.scss';
import type { TitleQuantityProps } from './TitleQuantityProps';

export const TitleQuantity = ({ isCurrentAdvisor, solution }: TitleQuantityProps) => {
  const solutionChangeQuantityMutation = useSolutionChangeQuantity();

  const handleQuantityChange = async (quantity: number) => {
    await solutionChangeQuantityMutation.mutateAsync({
      solutionId: solution.id,
      quantity
    });
  };

  const handleIncrease = async () => {
    await handleQuantityChange(solution.quantity.current + solution.quantity.incrementation);
  };

  const handleDecrease = async () => {
    handleQuantityChange(solution.quantity.current - solution.quantity.incrementation);
  };

  return (
    <div className={styles.TitleQuantityWrapper}>
      <Text.H3>{solution.name}</Text.H3>

      {isCurrentAdvisor && (
        <ButtonIcon
          onClick={handleDecrease}
          color="primary"
          backgroundColor="gray-light"
          icon={faMinus}
          disabled={solution.quantity.current === solution.quantity.min}
        />
      )}

      <Text.H3 as="span">{`x${solution.quantity.current}`}</Text.H3>

      {isCurrentAdvisor && (
        <ButtonIcon
          onClick={handleIncrease}
          color="primary"
          backgroundColor="gray-light"
          icon={faPlus}
        />
      )}
    </div>
  );
};
