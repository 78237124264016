import { faLocationDot, faUser } from '@fortawesome/pro-regular-svg-icons';

import { Icon, Rounded, SpacingGroup, Text } from '@Components/ui';

import styles from './ContactCard.module.scss';
import type { ContactCardProps } from './ContactCard.props';

export const ContactCard = ({ title, address, name, phone, email }: ContactCardProps) => {
  return (
    <Rounded className={styles.ContactCard}>
      <Text.H2 color="primary">{title}</Text.H2>

      <div className={styles.Item}>
        <Icon icon={faLocationDot} color="primary" />

        <Text.Paragraph>{address}</Text.Paragraph>
      </div>

      <div className={styles.BreakingLine} />

      <SpacingGroup className={styles.MobileGroup} spacing={6}>
        <div className={styles.Item}>
          <Icon icon={faUser} color="primary" />

          <Text.Paragraph>{name}</Text.Paragraph>
        </div>

        <div className={styles.Item}>
          <Text.Paragraph>{phone}</Text.Paragraph>
        </div>

        <div className={styles.Item}>
          <Text.Paragraph>{email}</Text.Paragraph>
        </div>
      </SpacingGroup>
    </Rounded>
  );
};
