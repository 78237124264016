import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { JsonParam, NumberParam, useQueryParams, withDefault } from 'use-query-params';

import { ProductCardRow } from '@Components/container';
import { LazyList, Modal } from '@Components/layout';
import { Form, Text } from '@Components/ui';
import { FacetKey, useAlgoliaGetProducts } from '@Hooks/Algolia/useAlgoliaGetProducts';
import { useAppRoutes } from '@Hooks/Common';
import { useRoomGetOne } from '@Hooks/Rooms';

import styles from './AddProductOverview.module.scss';
import type { AddProductOverviewProps } from './AddProductOverview.props';

export const AddProductOverview = ({ onSelect, isLoading }: AddProductOverviewProps) => {
  const [query, setQuery] = useQueryParams(
    {
      page: withDefault(NumberParam, 0),
      filter: JsonParam
    },
    {
      updateType: 'pushIn',
      removeDefaultsFromUrl: true
    }
  );

  const { t } = useTranslation('translation');
  const appRoutes = useAppRoutes();

  const { projectId, roomId } = useParams();
  const { data: room } = useRoomGetOne(roomId);

  const navigate = useNavigate();

  const { data: productsData, ...infiniteQuery } = useAlgoliaGetProducts({
    perPage: 8,
    filters: { roomTypes: room?.roomType.id, ...query.filter },
    facets: [FacetKey.APPLIANCES, FacetKey.SOLUTIONS, FacetKey.TYPE, FacetKey.ROOMTYPES]
  });

  const { data: filtersData } = useAlgoliaGetProducts({
    perPage: 1,
    facets: [FacetKey.APPLIANCES, FacetKey.SOLUTIONS, FacetKey.TYPE]
  });

  const allResults = productsData?.pages
    .map((pageData) => {
      return pageData.hits;
    })
    .flat(1);

  const handleClose = () => {
    navigate(appRoutes.getAdvisorProjectDetail(projectId).to);
  };

  if (!room) return null;

  return (
    <Modal
      title={appRoutes.getAdvisorProjectRoomAddProduct().label}
      open
      isLarge
      onOpenChange={handleClose}
      contentClassName={styles.ModalContent}
      className={styles.AddProductOverview}
    >
      <Form onChange={(filters) => setQuery({ filter: filters })}>
        <div className={styles.UpperWrapper}>
          <div className={styles.FilterWrapper}>
            <div className={styles.Filters}>
              {Object.entries(filtersData?.pages[0]?.facets ?? {}).map(([groupLabel, filters]) => {
                return (
                  <div className={styles.FilterGroup} key={groupLabel}>
                    <Text.Normal className={styles.Label}>
                      {t(`DOMAIN.PRODUCT.OVERVIEW.FILTERS.${groupLabel}` as any)}
                    </Text.Normal>

                    <Form.Input.MultiSelect
                      name={groupLabel.split('.')[0]}
                      options={Object.keys(filters).map((optionLabel) => {
                        return {
                          label: optionLabel,
                          value: optionLabel
                        };
                      })}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Form>

      <div
        className={cn(styles.Products, {
          [styles.isLoading]: isLoading
        })}
      >
        <LazyList {...infiniteQuery}>
          {allResults?.map((product) => {
            return (
              <ProductCardRow
                onClick={isLoading ? undefined : () => onSelect(product)}
                className={styles.Product}
                product={product}
                key={product.objectID}
              />
            );
          })}
        </LazyList>
      </div>
    </Modal>
  );
};
