import { Form as FormComponent } from './Form';
import {
  FormCheckbox,
  FormField,
  FormFile,
  FormMultiSelect,
  FormSearch,
  FormSelect,
  FormSingleCheckbox,
  FormText,
  FormTextArea
} from './internal/components';

export const Form = Object.assign(FormComponent, {
  Layout: {
    Field: FormField
  },

  Input: {
    Text: FormText,
    Textarea: FormTextArea,
    Search: FormSearch,
    File: FormFile,
    Checkbox: FormCheckbox,
    Select: FormSelect,
    SingleCheckbox: FormSingleCheckbox,
    MultiSelect: FormMultiSelect
  }
});
