import type { ControllerFieldState } from 'react-hook-form';
import { get } from 'react-hook-form';

import type { ExtractFunction, IBaseModel } from '@Constants/interfaces';

export const getFieldStateValidation = (fieldState: ControllerFieldState) => {
  const isError = !!fieldState.error === true;
  const isValid = !!fieldState.error === false && fieldState.isTouched;

  return {
    isValid,
    isError
  };
};
export const isFile = (input: unknown): input is File => {
  return 'File' in window && input instanceof File;
};

export const isFileList = (input: unknown): input is Array<File> => {
  return Array.isArray(input) && input.every(isFile);
};

export const modelToOptions = <T extends IBaseModel>(
  models?: T[],
  labelGetter: string | ExtractFunction<T> = 'name',
  valueGetter: string | ExtractFunction<T> = 'id'
) => {
  return (models ?? []).map((model) => {
    const label = typeof labelGetter === 'string' ? get(model, labelGetter) : labelGetter(model);
    const value = typeof valueGetter === 'string' ? get(model, valueGetter) : valueGetter(model);

    return {
      label,
      value
    } as const;
  });
};
