import { stringify } from 'qs';

import { i18n } from './TranslationUtils';

export const toQueryString = (params: any): string => {
  return stringify(params, { arrayFormat: 'repeat' });
};

export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.substring(1);
};

export const formatPercentage = (value: number, max = 1) => {
  const fraction = value / max;
  const { format } = new Intl.NumberFormat(i18n.language, { style: 'percent' });
  return format(fraction);
};

export const getReadableAddress = (address: {
  street: string;
  houseNumber: string;
  postalCode: string;
}) => `${address.street} ${address.houseNumber} | ${address.postalCode}`;
