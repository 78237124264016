import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import type { CreateProjectFormValues } from '@Components/form';
import { CreateProjectForm } from '@Components/form';
import { Modal } from '@Components/layout';
import { useAppRoutes } from '@Hooks/Common';
import { useCustomerGetCurrent } from '@Hooks/Customer';
import { useProjectCreate } from '@Hooks/Project';

import styles from './AdvisorCreateProjectScreen.module.scss';

export const AdvisorCreateProjectScreen = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.CUSTOMER.FORM.CREATE_CUSTOMER'
  });
  const appRoutes = useAppRoutes();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = useNavigate();
  const { mutateAsync: createProject } = useProjectCreate();

  const { data: customer } = useCustomerGetCurrent();

  if (!customer?.id) return null;

  const handleSubmit = async (data: CreateProjectFormValues) => {
    if (isLoading) return;

    setIsLoading(true);

    try {
      const { name, id } = await createProject({ ...data, customer });

      toast.success(t('SUCCESS_MESSAGE', { name }));

      handleClose(appRoutes.getAdvisorProjectDetail(id).to);
    } catch (error) {
      toast.error(t('ERROR_MESSAGE'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={appRoutes.getAdvisorProjectCreate().label}
      open
      className={styles.AdvisorCreateProjectScreen}
      isClosable={!isLoading}
      onOpenChange={(open) =>
        !open && !isLoading && handleClose(appRoutes.getAdvisorCustomerDetail(customer.publicId).to)
      }
    >
      <CreateProjectForm
        className={isLoading ? styles.isDisabled : undefined}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};
