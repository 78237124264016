export const DEFAULT_LANGUAGE = 'nl';
export const IS_PRODUCTION = process.env.VERCEL_ENV === 'production';
export const IS_LOCAL =
  typeof process.env.REACT_APP_VERCEL_ENV === 'undefined' && process.env.NODE_ENV !== 'production'; // TODO: Fix for production
export const HTTP_CODE_UNAUTHORIZED = 401;

export const SENTRY_DSN = '';

export const PAGE_SIZE_MD = 6;
export const PAGE_SIZE_LG = 9;

export const DASHBOARD_LINK = process.env.REACT_APP_DASHBOARD_URL;
export const ERROR_EMAIL_NOT_CONFIRMED = 'EmailNotConfirmed';
