import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';

import { QueryIndicator } from '@Components/ui';
import { useIsCustomer } from '@Hooks/Auth';
import { useAppRoutes } from '@Hooks/Common';
import { useCustomerGetCurrent } from '@Hooks/Customer';
import { acousticClient } from '@Services/Clients';
import { Redirect } from '@Utils/RouteUtils';

import { Page } from '../Page/Page';
import type { CustomerPageProps } from './CustomerPage.props';

export const CustomerPage = ({ children, ...props }: CustomerPageProps) => {
  const { customerId } = useParams();
  const isCustomer = useIsCustomer(customerId);
  const appRoutes = useAppRoutes();
  const homeUrl = appRoutes.getCustomerHome(customerId).to;
  const { data, ...query } = useCustomerGetCurrent();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (data) {
      acousticClient.setLocale(data.language);
      i18n.changeLanguage(data.language);
    }
  }, [data, i18n]);

  const links = [appRoutes.getCustomerHome(customerId), appRoutes.getCustomerProjects(customerId)];

  if (!isCustomer) {
    return <Redirect home="login" />;
  }

  return (
    <Page links={links} homeUrl={homeUrl} logoSrc={data?.company.image?.path} {...props}>
      <QueryIndicator modelName={t('DOMAIN.CUSTOMER.SINGULAR')} {...query}>
        <Outlet context={data} />
      </QueryIndicator>

      {children}
    </Page>
  );
};
