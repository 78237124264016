import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button, Form, SpacingGroup } from '@Components/ui';
import { useAuthRegister } from '@Hooks/Auth';
import { storageService } from '@Services/Storage';
import { isUserAdvisorFromUrl } from '@Utils/RouteUtils';

import styles from './AuthRegisterForm.module.scss';
import type { AuthRegisterFormProps, AuthRegisterFormValues } from './AuthRegisterForm.props';

export const AuthRegisterForm = ({ onSubmit, className, initialValues }: AuthRegisterFormProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.AUTHENTICATION.REGISTER.FORM'
  });
  const isAdvisor = isUserAdvisorFromUrl(window.location.href);

  const authRegister = useAuthRegister();

  const handleSubmit = useCallback(
    async (data: AuthRegisterFormValues) => {
      try {
        const params = { ...data };

        if (params.image) {
          const response = await storageService.upload(params.image[0]);

          if (response && response[0].id) params.image = { id: response[0].id };
        }

        await authRegister.mutateAsync(params);

        onSubmit?.(true);
      } catch (e) {
        onSubmit?.(false);
      }
    },
    [authRegister, onSubmit]
  );

  const passwordValidationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    password: Yup.string().password().required(),
    passwordConfirmation: Yup.string().password('password').required()
  });

  const passwordValidationSchemaAdvisor = Yup.object().shape({
    email: Yup.string().email().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    password: Yup.string().password().required(),
    phoneNumber: Yup.string().required(),
    passwordConfirmation: Yup.string().password('password').required(),
    image: Yup.mixed()
  });

  return (
    <Form<AuthRegisterFormValues>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={isAdvisor ? passwordValidationSchemaAdvisor : passwordValidationSchema}
      className={className}
    >
      <Form.Input.Text
        name="email"
        label={t('FIELDS.EMAIL.LABEL')}
        inputProps={{ placeholder: t('FIELDS.EMAIL.PLACEHOLDER'), disabled: true }}
      />

      <Form.Input.Text
        name="firstName"
        label={t('FIELDS.FIRST_NAME.LABEL')}
        inputProps={{ placeholder: t('FIELDS.FIRST_NAME.PLACEHOLDER') }}
      />

      <Form.Input.Text
        name="lastName"
        label={t('FIELDS.LAST_NAME.LABEL')}
        inputProps={{ placeholder: t('FIELDS.LAST_NAME.PLACEHOLDER') }}
      />

      <Form.Input.Text
        name="password"
        label={t('FIELDS.PASSWORD.LABEL')}
        inputProps={{ placeholder: t('FIELDS.PASSWORD.PLACEHOLDER'), type: 'password' }}
      />

      <Form.Input.Text
        name="passwordConfirmation"
        label={t('FIELDS.PASSWORD_CONFIRMATION.LABEL')}
        inputProps={{
          placeholder: t('FIELDS.PASSWORD_CONFIRMATION.PLACEHOLDER'),
          type: 'password'
        }}
      />

      {isAdvisor && (
        <>
          <Form.Input.Text
            name="phoneNumber"
            label={t('FIELDS.PHONE_NUMBER.LABEL')}
            inputProps={{ placeholder: t('FIELDS.PHONE_NUMBER.PLACEHOLDER') }}
          />

          <Form.Input.File name="image" label={t('FIELDS.IMAGE.LABEL')} />
        </>
      )}

      <SpacingGroup spacing={2} className={styles.Buttons}>
        <Button isSubmit type="submit">
          {t('FIELDS.SUBMIT.LABEL')}
        </Button>
      </SpacingGroup>
    </Form>
  );
};
