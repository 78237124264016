import { useInfiniteQuery } from 'react-query';

import { AlgoliaService } from '@Services/AlgoliaService/AlgoliaService';
import type { IAlgoliaProduct } from '@Services/AlgoliaService/AlgoliaService.props';

export const FacetKey = {
  APPLIANCES: 'appliances.title',
  MATERIAL: 'material.title',
  SOLUTIONS: 'solutions.title',
  TYPE: 'type.title',
  ROOMTYPES: 'roomTypes'
} as Record<string, string>;

interface AlgoliaParams {
  perPage: number;
  page?: number;
  filters?: Record<string, string | string[]>;
  facets: typeof FacetKey[keyof typeof FacetKey][];
}

export const useAlgoliaGetProducts = ({ perPage, filters, page = 0, facets }: AlgoliaParams) => {
  return useInfiniteQuery(
    [
      'algolia',
      'products',
      {
        perPage,
        ...filters
      }
    ],
    ({ pageParam = page }) => {
      const index = AlgoliaService.initIndex(
        `Acoustic_Products_${process.env.REACT_APP_AGOLIA_ENV}_nl-NL`
      );

      const facetFilters = Object.entries(filters || {}).map(([filterKey, filterProperties]) => {
        const filterArray = Array.isArray(filterProperties) ? filterProperties : [filterProperties];

        return filterArray.map(
          (filterProperty) => `${FacetKey[filterKey.toUpperCase()]}:${filterProperty}`
        );
      });

      return index.search<IAlgoliaProduct>('', {
        page: pageParam,
        hitsPerPage: perPage,
        attributesToRetrieve: ['*'],
        attributesToSnippet: ['*:20'],
        facetFilters,
        facets
      });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.page < lastPage.nbPages) {
          return lastPage.page + 1;
        }
      }
    }
  );
};
